import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

const Team = () => {
  return (
    <>
    <Navbar/>
    <div class="container-fluid page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">Our Team</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">Our Team</li>
                </ol>
            </nav>
        </div>
    </div>

{/* Our Team  */}
<div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="display-5 mb-5">Team Members</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-1.jpg" alt=""/>
                            <div class="team-social">
                         <small>   we provide positive feedback to our clients and maintain high quality standards and Defining and
                            executing the company’s long-term vision and goals </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Dashrath prajapati</h5>
                            <span class="text-primary">Managing director</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-2.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Akashy kumar</h5>
                            <span class="text-primary">Operation manager</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-3.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Shivam Kumar</h5>
                            <span class="text-primary">Sales Executive</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-4.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Ashish prajapati</h5>
                            <span class="text-primary">Product & Business
                            devolopment</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-3.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Mr.Krishnanand maurya</h5>
                            <span class="text-primary">Sales Manager</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-1.jpg" alt=""/>
                            <div class="team-social">
                         <small>   we provide positive feedback to our clients and maintain high quality standards and Defining and
                            executing the company’s long-term vision and goals </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Dashrath prajapati</h5>
                            <span class="text-primary">Managing director</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-3.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Shivam Kumar</h5>
                            <span class="text-primary">Sales Executive</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="images/team-4.jpg" alt=""/>
                            <div class="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Ashish prajapati</h5>
                            <span class="text-primary">Product & Business
                            devolopment</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
    </>
  )
}

export default Team