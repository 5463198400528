import React from 'react'

const Test = () => {
    
  return (
<>
<div class="container mt-5">
      <div class="row">
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <img class="" src="images/responsive-website.gif" alt=""/>
            </div>
            <div>
              <h6>Web Designing Services</h6>
              <p>Digital Innovations combines latest technology with creativity. We focus on
                quality and cost effective solutions to provide user-centric websites,
                E-commerce, Business portals, online communities, Social media campaigns
                and Mobile applications.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <img class="" src="images/gif2.gif" alt=""/>
            </div>
            <div>
              <h6>Mobile App Development</h6>
              <p>Digital Innovations combines latest technology with creativity. We focus on
                quality and cost effective solutions to provide user-centric websites,
                E-commerce, Business portals, online communities, Social media campaigns
                and Mobile applications.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</>
  )
}

export default Test