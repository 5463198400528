import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
const About = () => {
  return (
    <>
    <Navbar/>
    <div class="container-fluid page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
            </nav>
        </div>
    </div>

    <section className="section-services">
		<div className="container">
			<div className="row">
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-house-gear-fill"></i>
							</span>
							<h3 className="title">Home Security</h3>
							<p className="description">Security guards help protect valuable assets and intellectual property from theft, or unauthorized access, safeguarding the financial interests.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-universal-access-circle"></i>
							</span>
							<h3 className="title">Access Control</h3>
							<p className="description">Security guards help deter criminal activity such as theft, shoplifting, or trespassing, minimizing the risk of losses and damage to property</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
			
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-headset"></i>
							</span>
							<h3 className="title">24/7 Support</h3>
							<p className="description">Security guard services can provide round-the-clock security coverage, ensuring that your property is protected at all times.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-fingerprint"></i>
							</span>
							<h3 className="title">Enhanced Sense of Security</h3>
							<p className="description">Having security guards on-site provides a sense of security and peace of mind to employees, customers, visitors, and residents. </p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-file-earmark-lock2"></i>
							</span>
							<h3 className="title">Security Solutions</h3>
							<p className="description">Security guard services can be tailored to meet the specific needs and requirements of your business or property.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-shield-plus"></i>
							</span>
							<h3 className="title">Risk Prevention</h3>
							<p className="description">Security guards are trained to assess potential security risks and vulnerabilities and implement preventive measures to mitigate them. </p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
				
			</div>
		</div>
	</section>

 {/* About Start  */}
    <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container about px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 ps-lg-0 about-height">
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100 about-img" src="images/about.jpg" alt=""/>
                    </div>
                </div>
                <div class="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 pe-lg-0">
                        <div class="bg-primary mb-3 diff-height"></div>
                        <h1 class="div-heading display-5 mb-4">About Us</h1>
                        <p class="mb-4 pb-2">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet</p>
                        <div class="row g-4 mb-4 pb-3">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-1.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">1234</h2>
                                        <p class="fw-medium text-primary mb-0">Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-5.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">1234</h2>
                                        <p class="fw-medium text-primary mb-0">Projects Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="" class="btn btn-primary rounded-pill py-3 px-5">Explore More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Security Guard Services</h4>
                    <p className='pt-4'>Our highly trained security guards are the frontline defense for your property and assets.
                        With rigorous training and expertise in risk assessment, conflict resolution, and emergency response, our
                        security personnel are equipped to handle any situation with professionalism and efficiency.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Patrol Services</h4>
                    <p className='pt-4'>Our mobile patrol units offer an added layer of security, conducting regular patrols of your
                        premises to deter trespassing, vandalism, and other criminal activities. With GPS tracking and real-time
                        reporting, you can trust that your property is under constant surveillance</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/security.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new15.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Alarm Monitoring</h4>
                    <p className='pt-4'>We provide state-of-the-art alarm monitoring services to ensure rapid response
                        in the event of an emergency. Our monitoring center operates 24/7, monitoring fire alarms, intrusion alarms,
                        and surveillance systems, so you can rest easy knowing that help is always just a call away.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Event Security</h4>
                    <p className='pt-4'>Hosting a special event? Excelnet security offers expert event security services to ensure the
                        safety and well-being of your guests. From corporate gatherings to private parties, our experienced security
                        personnel will handle crowd control, access management, and emergency response, allowing you to focus on
                        hosting a successful event.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new11.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new5.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Consulting and Risk Assessment</h4>
                    <p className='pt-4'>Our team of security experts will work closely with you to assess your security needs and develop
                        a customized security plan tailored to your specific requirements. Whether you're looking to enhance the security
                        of your business, property, or personal assets, we'll provide strategic guidance and solutions to mitigate risks
                        and vulnerabilities.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default About