import React from 'react';
import Slider from 'react-slick';
import Navbar from './Navbar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './Footer';

// Custom Next Arrow
const NextArrow = ({ onClick }) => {
    return (
      <button className="carousel-next-arrow" onClick={onClick}>
        &#10095; {/* Unicode for right arrow */}
      </button>
    );
  };
  
  // Custom Prev Arrow
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="carousel-prev-arrow" onClick={onClick}>
        &#10094; {/* Unicode for left arrow */}
      </button>
    );
  };
const Landing = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
    
  return (
    <>
      <Navbar />
<a href="https://api.whatsapp.com/send?phone=7081056230&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." class="float" target="_blank">
<small className='me-2'>Connect for business requirement </small><i class="bi bi-whatsapp my-float"></i>
</a>
      <div className="container-fluid p-0 pb-5">
        <Slider {...settings}>
          <div className="carousel-item-wrapper">
            <img className="img-fluid" src="images/security.jpg" alt="Carousel 1" />
            <div className="carousel-content">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-12 text-center">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Your Safety at the ForeFront
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                    At ESS  we understand that safety and security are paramount. That's why we're dedicated to providing top-tier security solutions tailored to meet your unique needs. 
                    </p>
                    <a href="/read-more" className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animated slideInLeft">
                      Read More
                    </a>
                    <a href="/free-quote" className="btn btn-light rounded-pill py-md-3 px-md-5 animated slideInRight">
                      Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add other carousel items here */}
          <div className="carousel-item-wrapper">
            <img className="img-fluid" src="images/new21.jpg" alt="Carousel 2" />
            <div className="carousel-content">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-12 text-center">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Smart Security Solution For All Business
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                    As a leading security company, we specialize in comprehensive security services designed to protect your assets, property, and personnel. 
                    </p>
                    <a href="/read-more" className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animated slideInLeft">
                      Read More
                    </a>
                    <a href="/free-quote" className="btn btn-light rounded-pill py-md-3 px-md-5 animated slideInRight">
                      Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item-wrapper">
            <img className="img-fluid" src="images/carousel-2.jpg" alt="Carousel 3" />
            <div className="carousel-content">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-12 text-center">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Smart Security Solution For All Business
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                    Whether you're a small business, a large corporation, or an individual seeking peace of mind, SecureGuard is here to safeguard what matters most to you.
                    </p>
                    <a href="/read-more" className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animated slideInLeft">
                      Read More
                    </a>
                    <a href="/free-quote" className="btn btn-light rounded-pill py-md-3 px-md-5 animated slideInRight">
                      Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <section className="section-services">
		<div className="container">
			<div className="row">
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-house-gear-fill"></i>
							</span>
							<h3 className="title">Home Security</h3>
							<p className="description">Security guards help protect valuable assets and intellectual property from theft, or unauthorized access, safeguarding the financial interests.</p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-universal-access-circle"></i>
							</span>
							<h3 className="title">Access Control</h3>
							<p className="description">Security guards help deter criminal activity such as theft, shoplifting, or trespassing, minimizing the risk of losses and damage to property</p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
			
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-headset"></i>
							</span>
							<h3 className="title">24/7 Support</h3>
							<p className="description">Security guard services can provide round-the-clock security coverage, ensuring that your property is protected at all times.</p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-fingerprint"></i>
							</span>
							<h3 className="title">Enhanced Sense of Security</h3>
							<p className="description">Having security guards on-site provides a sense of security and peace of mind to employees, customers, visitors, and residents. </p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-file-earmark-lock2"></i>
							</span>
							<h3 className="title">Security Solutions</h3>
							<p className="description">Security guard services can be tailored to meet the specific needs and requirements of your business or property.</p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-shield-plus"></i>
							</span>
							<h3 className="title">Risk Prevention</h3>
							<p className="description">Security guards are trained to assess potential security risks and vulnerabilities and implement preventive measures to mitigate them. </p>
							<a href="/about" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
				
			</div>
		</div>
	</section>


      {/* <div class="container-xxl facts-box py-5">
    <div class="container">
        <div class="row g-4">

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-3.png" alt="Home Security Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">01</h1>
                    </div>
                    <h5 class="text-white">Home Security</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-4.png" alt="Access Control Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">02</h1>
                    </div>
                    <h5 class="text-white">Access Control</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-2.png" alt="24/7 Support Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">03</h1>
                    </div>
                    <h5 class="text-white">24/7 Support</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-3.png" alt="Home Security Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">04</h1>
                    </div>
                    <h5 class="text-white">Home Security</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-4.png" alt="Access Control Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">05</h1>
                    </div>
                    <h5 class="text-white">Access Control</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 bg-dark p-4 p-xl-5 card">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="btn-square rounded-circle icon-container">
                            <img class="" src="images/icon-2.png" alt="24/7 Support Icon"/>
                        </div>
                        <h1 class="display-1 mb-0">06</h1>
                    </div>
                    <h5 class="text-white">24/7 Support</h5>
                    <hr class="w-25"/>
                    <span class="text-white-50">Vero elitr justo clita lorem ipsum dolor at sed stet sit diam rebum ipsum et diam justo clita et.</span>
                </div>
            </div>

        </div>
    </div>
</div> */}


 {/* About Start  */}
    <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container about px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 ps-lg-0 about-height">
                    <div class="position-relative h-100 about-img-box">
                        <img class="position-absolute img-fluid w-100 h-100 about-img" src="images/new.jpg" alt=""  />
                    </div>
                </div>
                <div class="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 pe-lg-0">
                        <div class="bg-primary mb-3 diff-height"></div>
                        <h1 class="div-heading display-5 mb-4">About Us</h1>
                        <p class="mb-4 pb-2">
                        Welcome to Excelnet security system , where your safety is our priority.
Founded in 2021, we are a leading security solutions provider dedicated to
protecting people, property, and assets with unmatched expertise and cuttingedge technology. we understand the importance of safety and security in
today's world. Our dedicated team of trained professionals is committed to
providing top-notch security services tailored to meet your specific needs
                        </p>
                        <div class="row g-4 mb-4 pb-3">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-1.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">1234</h2>
                                        <p class="fw-medium text-primary mb-0">Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-5.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">1234</h2>
                                        <p class="fw-medium text-primary mb-0">Projects Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/about" class="btn btn-primary rounded-pill py-3 px-5">Explore More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

{/* Our Services  */}

<div class="container-xxl py-5">
        <div class="container">
            <div class="text-center">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="div-heading display-5 mb-5">Our Services</h1>
            </div>
            <div class="row g-0 service-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-9.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Security guard</h4>
                        <p class="mb-4">The mere presence of security guards can deter criminal activity such as theft and trespassing.</p>
                        <a class="btn" href="/service"><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-6.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">CCTV System</h4>
                        <p class="mb-4">A CCTV System offers 24/7 high-definition surveillance to protect and monitor your premises.</p>
                        <a class="btn" href="/service"><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-3.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Smart Home Security</h4>
                        <p class="mb-4">Smart Home Security offers remote monitoring and automated protection for your home.</p>
                        <a class="btn" href="/service"><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-8.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Finger Print Access</h4>
                        <p class="mb-4">ingerprint Access provides secure, convenient, and fast entry with biometric authentication.</p>
                        <a class="btn" href="/service"><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


{/* Why Choose Us */}

<div class="container mt-5">
<div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="div-heading display-5 mb-5">Why Choose Excelnet Security?</h1>
            </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <h6>Experience and Expertise</h6>
              <p> With years of experience in the security industry, SecureGuard has earned a reputation for excellence in providing
                reliable and effective security solutions. Our team consists of seasoned professionals who are committed to delivering
                superior service and exceeding client expectations.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <h6>Cutting-Edge Technology</h6>
              <p>We leverage the latest advancements in security technology to deliver innovative solutions that maximize protection and
                efficiency. From advanced surveillance systems to biometric access control, we stay at the forefront of security
                technology to keep you one step ahead of potential threats.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <h6>Commitment to Excellence</h6>
              <p>At SecureGuard, we take pride in our unwavering commitment to excellence. We hold ourselves to the highest standards
                of integrity, professionalism, and accountability, ensuring that every client receives the perfect and highest level of service
                and satisfaction.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="service-gif-content">
            <div>
              <h6>Customized Solutions</h6>
              <p>We understand that every client is unique, which is why we take a personalized approach to security. Whether you
                need basic security services or a comprehensive security strategy, we'll work closely with you to develop a solution
                that meets your specific needs and budget.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

{/* <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container feature px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 ps-lg-0">
                        <div class="bg-primary mb-3 diff-height"></div>
                        <h1 class="div-heading display-5 mb-5">Why Choose Us</h1>
                        <p class="mb-4 pb-2">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet</p>
                        <div class="row g-4">
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-7.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-primary mb-2">Trusted</p>
                                        <h5 class="mb-0">Security</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-10.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-primary mb-2">Quality</p>
                                        <h5 class="mb-0">Services</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-3.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-primary mb-2">Smart</p>
                                        <h5 class="mb-0">Systems</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-2.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-primary mb-2">24/7 Hours</p>
                                        <h5 class="mb-0">Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pe-lg-0 about-height">
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100 div-img" src="images/feature.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

{/* Our Projects  */}
<div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="div-heading display-5 mb-5">Our Gallery</h1>
            </div>
            {/* <div class="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                <div class="col-12 text-center">
                    <ul class="list-inline mb-5" id="portfolio-flters">
                        <li class="mx-2 active" data-filter="*">All</li>
                        <li class="mx-2" data-filter=".first">Complete Projects</li>
                        <li class="mx-2" data-filter=".second">Ongoing Projects</li>
                    </ul>
                </div>
            </div> */}
            <div class="row g-4 portfolio-container">
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-1.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">Business Security</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">Business Security</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-1.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-2.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">Fire Detection</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">Fire Detection</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-2.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-3.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">Access Control</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">Access Control</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-3.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.1s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-4.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">Alarm Systems</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">Alarm Systems</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-4.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-5.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">CCTV & Video</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">CCTV & Video</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-5.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-inner">
                        <img class="img-fluid w-100" src="images/portfolio-6.jpg" alt=""/>
                        <div class="text-center p-4">
                            <p class="text-primary mb-2">Smart Home</p>
                            <h5 class="lh-base mb-0">Smart CCTV Security Systems That Fits Your Business</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-primary mb-2">Smart Home</p>
                            <h5 class="lh-base mb-3">Smart CCTV Security Systems That Fits Your Business</h5>
                            <div class="d-flex justify-content-center">
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href="img/portfolio-6.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                <a class="btn btn-square btn-primary rounded-circle mx-1" href=""><i class="fa fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery-btn">
                <a href="/gallery" className="btn btn-primary">View More</a>
            </div>
        </div>
    </div>

{/* contact us  */}
<div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container quote px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 ps-lg-0 about-height">
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100 div-img" src="images/quote.jpg" alt=""/>
                    </div>
                </div>
                <div class="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 pe-lg-0">
                        <div class="bg-primary mb-3 diff-height"></div>
                        <h1 class="div-heading display-5 mb-5">Free Quote</h1>
                        <p class="mb-4 pb-2">Contact us today to
                        schedule a consultation and learn more about how we can tailor our security services to meet your needs</p>
                        <form className='form-box'>
                            <div class="row g-3">
                                <div class="col-12 col-sm-6">
                                    <input type="text" class="form-control border-0" placeholder="Your Name"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input type="email" class="form-control border-0" placeholder="Your Email"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input type="text" class="form-control border-0" placeholder="Your Mobile"/>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <select class="form-select border-0">
                                        <option selected>Select A Service</option>
                                        <option value="1">Service 1</option>
                                        <option value="2">Service 2</option>
                                        <option value="3">Service 3</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <textarea class="form-control border-0" placeholder="Special Note"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="submit">Get A Free Quote</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="container">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="div-heading display-5 mb-5">Our Blogs</h1>
            </div>
    </div>
    <div class="wrapper">
    <a href='/blogdetail' class="card">
      <div class="card-banner">
        <p class="category-tag popular">Security</p>
        <img class="banner-img" src='images/new15.jpg' alt=''/>
      </div>
      <div class="card-body">
        <p class="blog-hashtag">#Security #cctv</p>
        <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpYJQKSoM7S75P_KMRtQHqAAIPh133CSxByw&usqp=CAU' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Eleanor Pea</h3>
            <p class="profile-followers">5.2k followers</p>
          </div>
        </div>
      </div>
    </a>

    <a href='/blogdetail' class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
      <img class="banner-img" src='images/new11.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
        <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Darrell Steward</h3>
            <p class="profile-followers">15.7k followers</p>
          </div>
        </div>
      </div>
    </a>

    <a href='/blogdetail' class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
        <img class="banner-img" src='images/new5.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
      <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Savannah Nguyen</h3>
            <p class="profile-followers">18K followers</p>
          </div>
        </div>
      </div>
    </a>
    <a href='/blogdetail' class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
      <img class="banner-img" src='images/new16.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
      <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Savannah Nguyen</h3>
            <p class="profile-followers">18K followers</p>
          </div>
        </div>
      </div>
    </a>
  </div>

<Footer/>
    </>
  );
};

export default Landing;
