import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from "./Components/Landing";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import About from "./Components/About";
import Service from "./Components/Service";
import Contact from "./Components/Contact";
import Team from "./Components/Team";
import Test from "./Components/Test";
import Gallery from "./Components/Gallery";
import Blogdetail from "./Components/Blogdetail";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/blogdetail" element={<Blogdetail />} />
          <Route exact path="/test" element={<Test />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
